.container {
  margin-left: 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2rem;
}

.header {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}

.logo-container {
  margin: 0.5rem;
  width: 8rem;
}

.header-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem;
  text-align: center;
}

.subtitle {
  font-size: 1.875rem;
  font-weight: bold;
}

.title {
  font-size: 1.5rem;
}

.address,
.phone {
  font-size: 1.25rem;
}

.spacer {
  width: 10rem;
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.section-title {
  padding-top: 0.5rem;
  font-size: 1.25rem;
  font-weight: 500;
}

.section {
  font-size: 1.125rem;
}

.section-heading {
  background-color: #d1d5db;
  padding: 0.25rem;
  font-size: 1.25rem;
  font-weight: 500;
}

.text-small {
  font-size: 0.875rem;
}

.text-medium {
  font-size: 1rem;
  font-weight: 500;
}

.images {
  display: flex;
  width: 100%;
  flex-direction: row;
}

.image-container {
  height: 230px;
  width: 50%;
  overflow: hidden;
  border: 2px solid black;
}

.image-container:not(:last-child) {
  border-right: 0;
}

.image {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.geo-info {
  font-size: 0.875rem;
  font-weight: bold;
}

.footer {
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.signature {
  height: 200px;
  overflow: hidden;
}

.signature-image {
  height: 100%;
  object-fit: contain;
}
