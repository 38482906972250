.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 150ms ease-in-out;
  overflow: hidden;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Content {
  transition: transform 150ms ease-in-out;
  transform: translate(-50%, -500%);
}

.ReactModal__Content--after-open {
  transform: translate(-50%, -50%);
}

.ReactModal__Content--before-close {
  transform: translate(-50%, -500%);
}
