.report-container {
  margin: 20px;
  padding: 10px;
  background-color: #000080;
  display: flex;
  justify-content: center;
  align-items: center;
}

.report {
  width: 100%;
  height: 100%;
  padding: 5px;
  background-color: #000;
  font-family: Arial, sans-serif;
}

.report-header {
  padding: 5px;
  display: flex;
  align-items: center;
  background-color: #fff;
}

.logo {
  height: 60px;
  width: auto;
}

.header-title {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  line-height: 25px;
}

.section-title {
  height: 25px;
  font-weight: bold;
  font-size: 15px;
  color: #fff;
  background-color: #878787;
  border-top: 5px solid #000;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 0;
}

.identification-row {
  display: grid;
  grid-template-columns: 0.8fr 1.2fr;
  border-top: 5px solid #000;
}

.flex-row {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 5px;
  min-height: 40px;
  border-top: 5px solid #000;
  background-color: #fff;
}
.grid-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-top: 5px solid #000;
  background-color: #fff;
}

.reason-row {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 5px;
  border-top: 5px solid #000;
  background-color: #fff;
}

.reason-content {
  font-size: 16px;
}

.label-container {
  padding: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  background-color: #fff;
}

.label {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  line-height: 20px;
}

.translation {
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  line-height: 25px;
}

.omin-value {
  font-size: 25px;
  font-weight: bold;
  color: #bc1d74;
}

.checkbox {
  width: 30px;
  height: 30px;
}

.checkbox-row {
  padding: 5px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-top: 5px solid #000;
  background-color: #fff;
}

.checkbox-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.checkbox-row label {
  display: flex;
  align-items: center;
  font-weight: normal;
}

.checkbox-row input {
  margin-right: 5px;
}

.registration-content {
  padding: 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-right: 5px solid #000;
}

.registration-sign {
  padding: 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 200px;
  max-height: 200px;
}

.omni-image-container img {
  height: 150px;
  max-height: 150px;
  object-fit: cover;
}

.table {
  width: 100%;
  border-top: 5px solid #000;
  background-color: #fff;
}

.table-header {
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  background-color: #878787;
  color: #fff;
}

.table-body {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: #bc1d74;
}
